<template>
  <div id="text">
    <el-form @submit.native.prevent ref="form-text">
      <div
        :class="{
          'global-fonts': true,
          disabled: chartConfig ? false : true,
        }"
      >
        <div>
          <div class="el-collapse-item__header">Popular Fonts</div>

          <el-form-item class="mt1">
            <font-picker
              class="font-picker"
              :active-font="fontFamilyFromEl"
              :api-key="GOOGLE_FONTS_API_KEY"
              @change="changeFontFamily"
            ></font-picker>
          </el-form-item>
        </div>
      </div>

      <div class="mt2">
        <div class="el-collapse-item__header">Your uploaded Fonts</div>

        <ul>
          <li
            @click="changeCustomFont(font)"
            v-for="(font, index) in userUploadedFonts"
            :key="index"
            :style="{ fontFamily: font.font_name }"
          >
            {{ font.font_name }}
          </li>
        </ul>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getGoogleFontList } from "@/utils";

export default {
  data() {
    return {
      fontFamily: undefined, // google fonts will be applied to this
      customFontFamily: undefined, // user uploaded fonts will be applied to this
      customFontUrl: undefined,
    };
  },
  watch: {
    chartConfig: function(newProp) {
      if (!newProp) return;
      this.setNewOptions(newProp);
    },
  },
  computed: {
    ...mapActions("elementsModule", [
      "changeActiveElement",
      "changeActiveChart",
    ]),
    ...mapGetters("brandModule", ["userUploadedFonts"]),
    fontFamilyFromEl: function() {
      return this.fontFamily ? this.fontFamily.replace(/['"]+/g, "") : "";
    },
  },
  mounted: function() {
    const opts = this.getChartConfig().w.config;
    this.setNewOptions(opts);
    this.checkIfCustomFont(opts);
  },
  methods: {
    checkIfCustomFont(opts) {
      if (getGoogleFontList().indexOf(opts.chart.fontFamily) < 0) {
        // chart's fonts are not set as google font, hence we set it to customFont
        this.customFontFamily = opts.chart.fontFamily;
        this.customFontUrl = opts.chart.fontUrl;
      } else {
        this.fontFamily = opts.chart.fontFamily;
      }
    },
    setNewOptions(opts) {
      this.checkIfCustomFont(opts);
      // if (!this.customFontFamily) {
      //   this.fontFamily = opts.chart.fontFamily;
      // }
    },
    changeFontFamily(s) {
      this.fontFamily = s.family;
      this.customFontFamily = undefined;
      this.customFontUrl = undefined;
      this.updateChart(this.fontFamily, null);
    },
    changeCustomFont(font) {
      this.customFontFamily = font.font_name;
      this.customFontUrl = font.font_url;
      this.updateChart(this.customFontFamily, this.customFontUrl);
    },
    updateChart: function(fontFamily, fontUrl) {
      this.updateChartOptions({
        chart: {
          fontFamily: fontFamily,
          fontUrl: fontUrl,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.font-picker {
  margin-top: 10px;
}
.el-form-item__content {
  padding: 0 15px;
}

.disabled {
  cursor: not-allowed;

  .font-picker {
    pointer-events: none;
  }
}

ul {
  padding: 0 15px 0 25px;
  list-style: none;
  margin-top: 6px;

  li {
    padding: 5px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #333;
  }
}
</style>
